<template>
  <div class="">
    <div class="label-section">
        <div v-b-toggle.label-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Label Style</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="label-section-collapse" class="mt-2">
         <div class="">
          <div class="px-3">
      
            <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Label text:</span>
        <span class="d-flex align-items-center gap">{{!block.label.status?'Show':'Hide'}}  <b-form-checkbox
                       :id="`timer-label-show-title-${Date.now()}`"
                v-model="block.label.status"
                  ></b-form-checkbox></span>
      </label>
      </div>
      <div class="" v-if="block.label.status">
      <div class="px-3"  >
      <input type="text" v-model="block.label.text" placeholder="Button text" name="button-text" class="editor-input-control w-100">
     </div>
        <hr>
     <div class="px-3">
      <FontFamily  v-model="block.style.label.fontFamily"></FontFamily>
      <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
        <div class="d-flex align-items-center" style="gap:4px">
    <span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
      <input type="number" v-model="block.style.label.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
    </div>
        <FontStyles :textBold.sync="block.style.label.fontWeight" 
      :textItalic.sync=" block.style.label.fontStyle"
      :textUnderLine.sync="block.style.label.textDecoration" class=""></FontStyles>
       
      </div>

      <TextAlign v-model="block.style.label.textAlign"></TextAlign>
    
     </div>
     <hr>
     <div class="px-3">
      <SelectColor v-model="block.style.label.backgroundColor" :opacity.sync="block.style.label.backgroundOpacity" :label="'Background Color:'"></SelectColor>
  <SelectColor v-model="block.style.label.color" :opacity.sync="block.style.label.textOpacity"  class="mt-3" :label="'Text Color:'"></SelectColor>
     </div>
         </div>
        </div>
        </b-collapse>
          </div>
          <div class="timer-section">
        <div v-b-toggle.timer-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Timer Setting</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="timer-section-collapse" class="mt-2">
        <div class="px-3">
          <div class="select-per-page-count d-flex">
            <input type="number" v-model.number="block.timerValue" class="w-100" placeholder="Please Enter Time (Sec)" id="">
            <span>Sec.</span>
          </div>
        </div> 
        <hr>
        <div class="px-3">
          <div class="">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Initial text:</span>
        <span class="d-flex align-items-center gap">{{!block.counterSetting.initial.visible?'Show':'Hide'}}  <b-form-checkbox
                       :id="`timer-initial-text-${Date.now()}`"
                v-model="block.counterSetting.initial.visible"
                  ></b-form-checkbox></span>
      </label>
      <div class="select-per-page-count d-flex" v-if="block.counterSetting.initial.visible" >
            <input type="number" v-model.number="block.counterSetting.initial.text" class="w-100" placeholder="Initial Text" id="">
            
          </div>
    </div>
    <div class="mt-3">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Seconds:</span>
        <span class="d-flex align-items-center gap">{{!block.counterSetting.seconds.visible?'Show':'Hide'}}  <b-form-checkbox
                       :id="`timer-seconds-text-${Date.now()}`"
                v-model="block.counterSetting.seconds.visible"
                  ></b-form-checkbox></span>
      </label>
      <div v-if="block.counterSetting.seconds.visible" class="select-per-page-count d-flex" >
            <input type="number" v-model.number="block.counterSetting.seconds.text" class="w-100" placeholder="Seconds" id="">
            <select name="" v-model="block.counterSetting.seconds.textPosition" id="">
              <option value="first">First</option>
              <option value="end">End</option>
            </select>
          </div>
    </div>
    <div class="mt-3">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Minutes:</span>
        <span class="d-flex align-items-center gap">{{!block.counterSetting.minutes.visible?'Show':'Hide'}}  <b-form-checkbox
                       :id="`timer-minutes-text-${Date.now()}`"
                v-model="block.counterSetting.minutes.visible"
                  ></b-form-checkbox></span>
      </label>
      <div v-if="block.counterSetting.minutes.visible" class="select-per-page-count d-flex" >
            <input type="number" v-model.number="block.counterSetting.minutes.text" class="w-100" placeholder="Minutes" id="">
            <select name="" v-model="block.counterSetting.minutes.textPosition" id="">
              <option value="first">First</option>
              <option value="end">End</option>
            </select>
          </div>
    </div>
    <div class="mt-3">
          <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Hours:</span>
        <span class="d-flex align-items-center gap">{{!block.counterSetting.hours.visible?'Show':'Hide'}}  <b-form-checkbox
                       :id="`timer-hours-text-${Date.now()}`"
                v-model="block.counterSetting.hours.visible"
                  ></b-form-checkbox></span>
      </label>
      <div v-if="block.counterSetting.hours.visible" class="select-per-page-count d-flex" >
            <input type="number" v-model.number="block.counterSetting.hours.text" class="w-100" placeholder="Hours" id="">
            <select name="" v-model="block.counterSetting.hours.textPosition" id="">
              <option value="first">First</option>
              <option value="end">End</option>
            </select>
          </div>
    </div>
    
        </div>
        <hr>
     <div class="px-3">
      <FontFamily  v-model="block.style.fontFamily"></FontFamily>
      <div class="mt-3 d-flex gap">
        <div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number"  v-if="isDesktopView" v-model="block.style.buttonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number" v-if="isDesktopView"  v-model="block.style.buttonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
      </div>
      <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
        <div class="d-flex align-items-center" style="gap:4px">
    <span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
      <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
    </div>
        <FontStyles :textBold.sync="block.style.fontWeight" 
      :textItalic.sync=" block.style.fontStyle"
      :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
       
      </div>

      <TextAlign v-model="block.style.textAlign"></TextAlign>
    
     </div>
     <hr>
     <div class="px-3">
      <SelectColor v-model="block.style.backgroundColor" :opacity.sync="block.style.backgroundOpacity" :label="'Background Color:'"></SelectColor>
  <SelectColor v-model="block.style.color" :opacity.sync="block.style.textOpacity"  class="mt-3" :label="'Text Color:'"></SelectColor>
     </div>
     <hr>
     <div class="px-3">
      <label for="" class="editor-label">Stroke:</label>
      <Stroke :borderRadius.sync="block.style.borderRadius" :borderSize.sync="block.style.borderSize"></Stroke>
     </div>
     <hr>
     <div class="px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
     <hr class="mb-0">
        </b-collapse>
        <div class="px-3 my-3">
          <label for="" class="editor-label">Timeout Action:</label>
          <div class="d-flex w-100 gap align-items-center">
            <svg  width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g  id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g  id="SVGRepo_iconCarrier"><path  d="M14 12C14 14.7614 11.7614 17 9 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H7.5M10 12C10 9.23858 12.2386 7 15 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17H16.5" stroke="#B3AFB6" stroke-width="2" stroke-linecap="round"></path></g></svg>
            <select name=""    v-model="block.timerAction" id="" class=" w-100 m-0 editor-input-control">
              <option value="none" selected="selected">None</option>
          <option value="text">Display Text</option>
            <option value="nextPage">Next Page</option>
            <optgroup label="Go to URL:">
              <option value="newTab">New Tab</option>
            <option value="currentTab">Current Tab</option>
            </optgroup>
            </select>
          </div>
          <div class="mt-3" v-if="block.timerAction == 'newTab' || block.timerAction == 'currentTab'">
            <label for="" class="editor-label">Timeout Action:</label>
            <input  type="url"  v-model="block.timerActionLink"  placeholder="URL Link" class="editor-input-control w-100">

          </div>
          <div class="mt-3" v-if="block.timerAction == 'text'">
            
            
            <label for="" class="editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Preview text:</span>
        <span class="d-flex align-items-center gap">{{!block.actionText.preview?'Show':'Hide'}}  <b-form-checkbox
                       :id="`timer-label-show-title-${Date.now()}`"
                v-model="block.actionText.preview"
                  ></b-form-checkbox></span>
      </label>
      <div class="editor-input-control mt-3">

        <input  type="text"  v-if="block.actionText.preview"  v-model="block.actionText.text"  placeholder="Preview Text" class=" w-100">
      </div>

          </div>
        </div>
          </div>
  
</div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Stroke from "../MiniComponents/Stroke.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";

export default {
  components:{
    TextAlign,
FontFamily,
SelectColor,
FontStyles,
Stroke,
Padding
  },
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
  },
  data() {
    return {
      showBgOpacity: true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };

    //   this.contentLocal = this.content;
  },
  methods: {
    checkHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorOutput(value) {
      let isHex = this.checkHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
textBlockFont(){
      return this.block.style.fontFamily
    },
    labelFont(){
      return this.block.style.label.fontFamily
    },
  },
  watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    labelFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
  }
};
</script>
<style>
 .editor-input-control select{
    border:none;
    outline: none;
  }
  .editor-input-control:focus-within{
    box-shadow: 0px 0px 1px 1px var(--secondary-color);
  }</style>
